import React from "react";
import css from './Works.module.scss';
// import {motion } from 'framer-motion';

import { workExp } from "../../utils/data";
const Works = () => {
    return(
        <section 
        
        className={`paddings ${css.wrapper}`}>


        <a className="anchor" id="work"></a>


        <div className={`flexCenter innerWidth ${css.container}`}>
            <span className="primaryText yPaddings">My Work Experince</span>

            <div className={`flexCenter ${css.experience}`}>
                {workExp.map((exp,  i) => {
                    return (
                    <div className={`flexCenter ${css.exp}`} 
                    
                    key={i}> 
                    
                    <div className={css.post}>
                        <h1>{exp.place}</h1>
                        <p>{exp.tenure}</p>

                    </div>
                    <div className={css.role}>
                        <h1>{exp.role}</h1>
                        <p>{exp.detail}</p>
                    </div>
                    </div>
                    );
                })

                }

                <div className={css.progressbar}>
                    <div className={css.line}></div>
                    <div><div className={css.circle} style={{background: "#286F6C"}}></div></div>
                    <div><div className={css.circle} style={{background: "#F2704E"}}></div></div>
                    <div><div className={css.circle} style={{background: "EEC048"}}></div></div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Works; 