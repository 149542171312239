import React from "react";
import css from './Footer.module.scss'

const Footer = () => {
    return (
        <section className={`paddings ${css.wrapper}`}>

 

            <div className={`innerWidth yPaddings flexCenter ${css.container}`}>
                <div className={css.left}>

                    <span className="primaryText">
                    We should queue up some time to chat. <br/>I'll buy the coffee.
                    </span>

                    <span className="primaryText">
                        Start by <a href="mailto:murphysam.0500@gmail.com">saying Hi!</a>
                    </span>
                </div>
                <div className={css.right}>

                    <div className={css.info}>
                        {/* <span className="secondaryText">Information</span> */}
                        <p style={{fontStyle: "italic"}}>Ambur, Tamilnadu, India</p>
                    </div>

                    <div className={css.menu}>
                        <li>Services</li>
                        <li>Works</li>
                        <li>Notes</li>
                        <li>Experience</li>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;