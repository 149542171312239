import React, { useRef, useState, useEffect } from "react";
import css from "./Header.module.scss";
import {BiMenuAltRight, BiPhoneCall} from 'react-icons/bi';

import { getMenuStyles } from "../../utils/motion";
import useHeaderShadow from "../../hooks/useHeaderShadow";
import useOutsideAlerter from '../../hooks/useOutsideAlerter'

const Header = () => {
    const menuRef = useRef(null);
    const [menuOpened, setMenuOpened] = useState(true);
    const headerShadow = useHeaderShadow(); 

    useOutsideAlerter(
        {
            menuRef,
            setMenuOpened
        }
    );
    return(

         <div
         initial="hidden"
         className={`bg-primary paddings ${css.wrapper}`}
         viewport={{ once: true, amount: 0.25 }}
         style={{boxShadow: headerShadow}}
         >

            <div className={`innerWidth ${css.container} flexCenter`}>
                <div className={css.name}>
                    Murphy
                </div>

                <ul 
                ref={menuRef}
                style = {getMenuStyles(menuOpened)}
                className={`flexCenter ${css.menu}`}>
                    <li><a href="">Services</a></li>
                    <li><a href="#work">Experience</a></li>
                    <li><a href="#actions">Portfolio</a></li>

                    <li className={`flexCenter ${css.phone}`}>
                        <p>+91-7339263345</p>
                        <BiPhoneCall size={'40px'}/>

                        </li>
                </ul>
                <div className={css.menuIcon}
                onClick={() => setMenuOpened((prev)=> !prev)}>
                    <BiMenuAltRight size={30}/>

                </div>


            </div>
         </div>
    );
}

export default Header;










// import React, { useState } from "react";
// import css from "./Header.module.scss";
// import { BiMenuAltRight, BiPhoneCall } from "react-icons/bi";

// const Header = () => {
//     const [menuOpened, setMenuOpened] = useState(false);

//     const toggleMenu = () => {
//         setMenuOpened(!menuOpened);
//     };

//     return (
//         <div className={`bg-primary paddings ${css.wrapper}`}>

//             <div
//                 className={`innerwidth flexCenter ${css.container}`}>
//                 <div className={css.name}>Murphy</div>

//                 <div className={`${css.menuIcon}`} onClick={toggleMenu}>
//                     <BiMenuAltRight size={30} />
//                 </div>

//                 {menuOpened && (
                    
//                         <ul className={`flexCenter ${css.menu}`}>
//                             <li>
//                                 <a href="#">Services</a>
//                             </li>
//                             <li>
//                                 <a href="#work">Experience</a>
//                             </li>
//                             <li>
//                                 <a href="#actions">Portfolio</a>
//                             </li>
//                             <li className={`flexCenter ${css.phone}`}>
//                                 <p>+123 456 7890</p>
//                                 <BiPhoneCall size={"40px"} />
//                             </li>
//                         </ul>
                    
//                 )}


//             </div>
//         </div>
//     );
// };

// export default Header;

