import React from "react";
import css from "./Hero.module.scss";

function Hero(){
    return (
        <section className={`paddings ${css.wrapper} `}>
            <div className={`innerWidth ${css.container}`}>

                {/* Upper Elements*/}
                <div className={css.upperElements}>
                    <span className="primaryText">Hey There, <br/> I'm Murphy.</span>
                    <span className="secondaryText"> I learn and design beautiful simple
                        <br/>
                        things, And I love what i do. 
                    </span>
                </div>
                {/*person image */}
                <div className={css.person}>
                    <img src="./profile.png" alt="profile_logo" width="400" height="380" />
                </div>

                {/*perosn email */}
                <a className={css.email} href="mailto:mu]rphysam2000@gmail.com">
                    murphysam.0500@gmail.com
                </a>

                {/*Lower Elements*/}
                <div className={css.lowerElements}>
                    <div className={css.experience}>
                        <div className="primaryText">6</div>
                        <div className="secondaryText">
                            <div>Months</div>
                            <div>Intern Experience</div>
                        </div>
                    </div>

                </div>

            </div>
            
        </section>
    )
}


export default Hero; 