import Header from "./components/Header/Header";
import css from "./styles/app.module.scss";
import Hero from "./components/Header/Hero/Hero";
import Works from "./components/Works/Works";
import Portfolio from "./components/Portfolio/Portfolio";

import Footer from "./components/Footer/Footer";
function App(){
  return (
    <div className={`bg-primary ${css.container}`}>
      <Header />
      <Hero />
      <Works />
      <Portfolio/>
      
      <Footer />
    </div>

  );
}
  


export default App;
