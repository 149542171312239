import React from "react"
import css from './Portfolio.module.scss'
// import {motion} from "framer-motion"

const Portfolio = () => {
    return(
        <section 
        
        className={`paddings ${css.wrapper}`}>

            <a className="anchor" id="actions"></a>
            <div className={`innerWidth flexCenter ${css.container}`}>

                <div className={`flexCenter ${css.heading}`}>
                    <div>
                        <span className="primaryText">My Works</span>
                        <p style={{marginTop: "10px"}}>Dynamic UI designs for digital experience.</p>
                    </div>
                    <span className="secondaryText">
                        Explore my Works
                    </span>
                </div>


                <div className={`flexCenter ${css.showCase}`}>
                    <img
                    
                    src='./showCase1.png'
                    alt="project"
                    /> 

                    <img
                    
                    src='./showCase2.png'
                    alt="project"
                    /> 

                    <img
                    
                    src='./showCase3.png'
                    alt="project"
                    /> 
                </div>
            </div>
        </section>
    )
}

export default Portfolio;